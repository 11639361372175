@import '../../../assets/sass/variables';

.main-footer {
  background-color: $color-blue;
  color: #fff;
  padding: 30px 0 15px 0;

  h2 {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 14px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
    letter-spacing: 5px;
    margin-bottom: 15px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 30px;

    li {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 2px;

      a {
        color: rgba(255, 255, 255, 0.6);
        transition: 0.12s;

        &:hover {
          text-decoration: none;
          color: rgba(255, 255, 255, 1)
        }
      }
    }

    &.social-links {
      display: flex;

      li {
        margin-right: 20px;
      }
    }
  }

  .separator {
    background: rgba(255,255,255,0.2);
  }

  .copyright {
    font-size: 13px;
    color: rgba(255,255,255,0.6);
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .main-footer {
    padding: 60px 0 20px 0;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}