@import 'variables';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  font-family: $main-font;
  overflow-x: hidden;
  padding-top: 80px;

  &.locked {
    overflow: hidden;
  }

  .-smallmarginright {
    margin-right: 5px;
  }
}

.main-content {
  overflow: hidden;
  margin-top: 100px;
  &.-nomargin {
    margin-top: 0;
  }
}

.section-our-values {
  background-color: $color-lighter;
  padding-top: 60px;
}

.section-career {
  padding-top: 30px;
}

.animation-container {
  width: 100%;
  height: 0;
  position: relative;
  margin-bottom: 40px;
  z-index: -2;

  > div {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &.animation-about {
    padding-top: 110.73%;
  }

  &.animation-how-we-work {
    padding-top: 142.9%;

    > div {
      left: -110px;
      top: 50px;
    }
  }

  &.animation-our-story {
    padding-top: 35%;
    margin-top: -140px;
    margin-bottom: 200px;

    > div {
      left: -60px;
    }
  }

  &.animation-our-values {
    margin-top: 100px;
    margin-bottom: 0;
    padding-top: 60.4%;
    z-index: 1;
  }

  &.animation-career {
    padding-top: 42.29%;
    margin-bottom: 0;
  }
}

.consent-container {
    font-family: 'Poppins', sans-serif;
    background-color: #171955 !important;
    padding: 20px 30px 20px 60px !important;
    background-repeat: no-repeat !important;
    background-position: 20px 37px !important;
    background-image: url(../images/cookie.svg) !important;
    align-items: end !important;
    display: block !important;

    .consent-button {
        background: #606297 !important;
        color: white !important;
        padding: 5px 15px;

        &:focus, &:hover {
            outline: none !important;
            box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1) !important;
        }
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    body {
        padding-top: 100px;
    }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  body {
    &.locked {
      padding-right: 17px;

      .main-navbar {
        padding-right: 17px;
      }
      .main-menu {
        li {
          position: relative;
          left: -8px;
        }
      }
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  body {
    padding-top: 100px;
  }

  .main-content {
    overflow: visible;
  }

  .section-about {
    margin-bottom: 40px;
  }

  .section-our-story {
    margin-top: -100px;
  }

  .section-our-values {
    margin-top: -100px;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: inherit;
      width: 100%;
      height: 100%;
      top: 0;
      right: 100%;
    }

    &:after {
      right: auto;
      left: 100%;
    }
  }

  .section-career {
    padding-top: 80px;
  }

    .animation-container {
        &.animation-our-story {
            margin-top: -250px;
            right: -50px;
            padding-top: 100%;
        }

        &.animation-our-values {
            padding-top: 28%;
        }

        &.animation-how-we-work {
            > div {
                top: -25px;
                left: -170px;
            }
        }
    }
    }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
   
}

@media (min-width: 1024px) {
    .consent-container {
        padding: 20px 30px 20px 11% !important;
        background-position: 8% 37px !important;
    }
}

@media (min-width: 1440px) {
    .consent-container {
        padding: 20px 30px 20px 17% !important;
        background-position: 15% 37px !important;
    }
}

@media (min-width: 1680px) {
    .consent-container {
        padding: 20px 30px 20px 22% !important;
        background-position: 20% 37px !important;
    }
}

@media (min-width: 1920px) {
    .consent-container {
        padding: 20px 30px 20px 25% !important;
        background-position: 23% 37px !important;
    }
}

@media (max-width: 991px) {
    .animation-container {
        &.animation-our-values {
            padding-top: 30%;
            > div {
                left: 5%;
            }
        }
    }
}
@import 'ourvalues';
@import 'ourstory';
@import 'howwework';
@import 'careers/careers';
@import 'typography';
@import 'next3'; // this one is added because some styling from it is used on other places (e.g. positions button in navbar)