.main-navbar {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  background: transparent;
  padding: 0;
  h1 {
    display: inline-block;
  }

  &.translucent {
    background: rgba(255, 255, 255, 0.9);
  }

  &.transparent {
    background: #050b4a;
  }

  .container {
    display: flex;
    justify-content: space-between;
  }

  .main-menu-toggle {
    background: none;
    border: none;
    outline: none;

    img {
      width: 18px;
    }
  }
  .positions {
    margin-left: auto;
    margin-right: 30px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .main-navbar {
    padding: 10px 0;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .main-navbar {
    padding: 20px 0;

    .main-menu-toggle {

      img {
        width: 30px;
      }
    }
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
