@import '../../../assets/sass/variables';

.main-menu {
  position: fixed;
  display: flex;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: $color-lighter;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  li {
    padding: 0 15px;
    margin-bottom: 10px;
  }
  .nav-link {
    display: inline-block;
    padding: 0;
    font-size: 2em;
    color: #000;
    font-weight: bold;
    line-height: 1.2;
    border-bottom: 5px solid transparent;
    transition: 0.2s;
    &.active {
      border-bottom: 5px solid #000;
    }
  }
}

.fade {
  &.enter-active {
    opacity: 0.01;
  }
  &.enter-done {
    opacity: 1;
  }
  &.exit {
    opacity: 1;
  }
  &.exit-active {
    opacity: 0.01;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .main-menu {
    li {
      padding-left: 100px;
    }
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .main-menu {
    li {
      padding-left: 140px;
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .main-menu {
    li {
      padding-left: 160px;
    }
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .main-menu {
    li {
      padding-left: 180px;
    }
  }
}
