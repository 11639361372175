@import '../../assets/sass/variables';

.contact-form {
  margin-bottom: 60px;
  input, textarea {
    background-color: rgba(255, 255, 255, 0.05);
    border: none;
    color: #ffff;
    width: 100%;
    height: 40px;
    padding: 10px 15px;
    font-size: 14px;
    margin-bottom: 10px;
    outline: none;

    &:focus {
      &::-webkit-input-placeholder {
        color: transparent !important;
      }

      &::-moz-placeholder {
        color: transparent !important;
      }

      &:-moz-placeholder {
        color: transparent !important;
      }
    }
  }

  textarea {
    resize: none;
    height: 150px;
  }

  button {
    text-transform: uppercase;
    background: transparent;
    border: 2px solid rgba(255,255,255,0.2);
    color: #fff;
    padding: 5px 50px;
    margin-top: -7px;
    transition: 0.12s;
    outline: none;
    float: right;
    &:hover {
      color: $color-blue;
      background-color: #fff;
    }
  }

  ::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  ::-moz-placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  :-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  :-moz-placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
}