@import "../../assets/sass/variables";

.highlighted {
  background-color: $color-lighter;
  padding: 30px 0;

  h3 {
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    margin: 0;
  }
}

.culture-description {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 20px;
  font-weight: 500;
  width: 100%;

  li {
    list-style: none
  }
}

.culture-text {
  margin-bottom: 90px;

  a {
    text-decoration: none;
    color: #000;
    font-weight: bold;
  }
}

@media (max-width: 767px) {
    .culture-description {
        padding: 0;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
    .culture-row {
        align-items: center;
        flex-direction: column;
    }

    .culture-description {
        text-align: center;
        li {
            margin: 10px;
        }
    }

    .culture-text {
        margin-bottom: 50px;
    }

    .culture-image {
        padding-left: 0;
    }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
    .culture-image {
        padding-left: 60px;
    }
  .highlighted {
    padding: 40px 0;

    h3 {
      font-size: 28px;
    }

    p {
      font-size: 20px;
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
