.title {
    font-size: 24px;
    &.-center {
        text-align: center;
    }
    &.-bold {
        font-weight: bold;
    }
    &.-vertical-margin {
        margin: 30px 0;
    }
}
.subtitle {
    &.-center {
        text-align: center;
    }
    &.-gray {
        color: $light-gray;
    }
    &.-marginbottom30 {
        margin-bottom: 30px;
    }
    &.-marginbottom50 {
        margin-bottom: 50px;
    }
}
.-white {
    color: $white !important;
}