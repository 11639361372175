@import '../../../assets/sass/variables';

.accordion {
  margin: 30px -15px 0 -15px;
}

.a-item {
    margin-bottom: 12px;

    .a-header {
        padding: 20px 15px;
        background-color: $white;
        color: #000;
        cursor: pointer;
        font-weight: 600;

        h4 {
            display: flex;
            font-size: 20px;
            margin: 0;
            justify-content: space-between;

            img {
                content: url(../../../assets/images/down.svg);
            }
        }

        &:hover {
            background-color: lighten($color-violet, 10%);
            color: $white;

            h4 img {
                content: url(../../../assets/images/down_white.svg);
            }
        }

        &.expanded {
            background-color: lighten($color-violet, 10%);
            color: $white;

            h4 img {
                content: url(../../../assets/images/up_white.svg);
            }
        }
    }

    .a-body {
        padding: 20px 15px;
        background-color: #ffffff;

        p {
            color: #000000;
            font-size: 14px;
        }

        h5 {
            color: #000000;
            text-transform: uppercase;
            font-size: 14px;
            margin: 20px 0 5px 0;
            letter-spacing: 5px;
            font-weight: 600;
        }

        ul {
            padding: 0;
            color: #000000;
            list-style: none;
            font-size: 14px;

            li {
                margin-bottom: 3px;
            }
        }

        a {
            color: $color-blue;
        }

        .acc-apply-section {
            padding-top: 16px;
            margin-bottom: -4px;
            border-top: 1px solid #4f52a3;
            display: table;
            vertical-align: bottom;
            width: 100%;

            .acc-apply-cell {
                display: table-cell;
                vertical-align: middle;
            }

            div.acc-apply-cell {
                vertical-align: bottom;
            }

            .hand-right {
                display: inline-block;
                font-size: 35px;
                line-height: 10px;
                position: relative;
                top: 6px;
                margin-left: 2px;
            }

            .acc-apply-btn {
                padding: 5px 10px;
                border-radius: 6px;
                border: none;
                background-origin: border-box;
                user-select: none;
                -webkit-user-select: none;
                touch-action: manipulation;
                width: 100%;
                color: #fff;
                font-size: 14px;
                white-space: nowrap;
                background: linear-gradient(180deg, #4f52a3 0%, #3e4181 100%);
                box-shadow: 0px 0.5px 1.5px rgba(79, 82, 163, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);

                &:focus, &:hover {
                    outline: 0;
                    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .a-item .a-body .acc-apply-section {
        margin-top: 20px;

        .acc-apply-cell {
            display: table-row;
            vertical-align: middle;
        }

        div.acc-apply-cell {
            text-align: right;
        }

        .hand-right {
            display: none;
        }

        .acc-apply-btn {
            width: auto;
            margin-top: 10px;
        }
    }
}

    /* Small devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {
        .accordion {
            margin: 30px 0 0 0;
        }
    }

    /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
    @media (min-width: 768px) {

    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {

    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {

    }