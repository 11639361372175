html {
  scroll-behavior: smooth;
}
body {
  padding-top: 0px;
}

.-red {
  color: #FF6161;
}
.-green {
  color: #2c9648;
}
.-black {
  color: #000000;
}
.main-content {
  overflow: hidden;
}

.transparent {
  background: transparent;
  -webkit-transition: all 1s;
  /* Safari prior 6.1 */
  transition: all 1s;
 
}

.translucent {
  -webkit-transition: all 1s;
  /* Safari prior 6.1 */
  transition: all 1s;
}

a.read-more {
  color: #ffffff;
  cursor: pointer;

  &:after {
    content: "";
    display: block;
    background-color: #6fddd2;
    border-bottom: 3px solid #6fddd2;
    width: 91px;
    margin-top: -8px;
  }

  &:hover {
    text-decoration: none;
    color: #6fddd2;
  }
}

#values-propeller {
  background-color: #ebedfb;
  padding-top: 95px;
  padding-bottom: 165px;
  h1 {
    margin-bottom: 77px;
    font-size: 36px;
    line-height: 1;
    font-weight: bold;
  }

  h2 {
    margin-bottom: 25px;
    font-weight: bold;
    font-size: 28px;
  }

  .-bottom-margin {
    margin-bottom: 48px;
  }
}

#jobs {
  background-color: #050b4a;
  padding-top: 116px;
  padding-bottom: 154px;

  h1 {
    font-weight: bold;

    &.-inverted {
      color: #fff;
    }
  }
}

#apply-now {
  background-color: #050b4a;
  padding-top: 10px;
  padding-bottom: 100px;

  h2 {
    font-weight: bold;
    margin-bottom: 36px;
    color: white;
  }

  input,
  textarea {
    background-color: #fff;
    margin-bottom: 32px;
    border-color: #fff;
    padding: 23px 15px;
    border-radius: 0px;
    color: rgba(5, 9, 63, 0.4);

    &::placeholder {
      color: rgba(5, 9, 63, 0.4);
    }
  }

  .uploadwapper {
    .addimage {
      display: none;
    }

    label {      
      cursor: pointer;
      text-align: center;
    }

    input {
      padding: 0;
    }

    .attach {
      display: none;

      &.-error {
        border: 1px solid #FF6161;
      }
    }

    .file-name {
      margin-left: 20px;
      color: #ffffff;
      &.-center {
        display: flex;
      justify-content: center;
      }
    }

    .displayImage {
      width: 120px;
      height: 120px;
      overflow: hidden;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border: 1px solid #C7C7CD;
    }

    .notice {
      font-size: 12px;;
    }
  }

  .form-group {
    margin-bottom: 20px;
  }

  textarea {
    min-height: 125px;
  }
}

.app-button {
    color: #000000;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 55px 10px 55px;
    background-color: #ebedfb;
    border-radius: 5px;
    display: inline-block;
    font-weight: 600;
    width: 100%;
    max-width: 260px;
    border: 0;
    outline: none;

    &.-link {
        cursor: pointer;
        text-decoration: none;
        &:hover {
            color: #6fddd2;
        }
    }
    &.-darklink {
        text-decoration: none;
        cursor: pointer;
        &:hover {
            color: #000000;
        }
    }

    &:disabled {
        cursor: no-drop;
        opacity: 0.8;
        color: #6b6b6b;
    }
    &.-apply {
        &:hover {
            opacity: 1;
        }
    }
    &.-attach {
        background-color: #616697;
        padding: 10px 20px 10px 20px;
        text-align: center;
        text-transform: uppercase;
    }
    &.-earthapply {
        margin-top: 50px;
    }
    &.-outlined {
        background: transparent;
        line-height: 16px;
    }
    &.-whiteborder {
        border: solid 2px #ffffff;
    }
    &.-blackborder {
        border: solid 2px #000000;
    }
    &.-blueborder {
        border: solid 2px #2b3177;
    }
    &.-bluetext {
        color: #2b3177;
    }
    &.-whitetext {
        color: #ffffff;
    }
    &.-whiteborder {
        border: 2px #ffffff solid;
    }
    &.-greenborder {
        border: 2px #00dccb solid;
    }
    &.-marginright {
        margin-right: 10px;
    }
    &.-fixedheight {
        height: 40px;
    }
    &.-round {
        border-radius: 50px;
    }
    &.-automarginleft {
        margin-left: auto;
    }
    &.-position {
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        max-width: 200px;
        margin-right: 30px;
        height: 40px;
        margin-top: 9px;
        padding: 0;
        outline: none;
        text-transform: none;
        &.-marginRight0 {
            margin-right: 0;
        }
        &.-white {
            border-color: #ffffff;
        }
    }
}

#landing {
  overflow: hidden; 
  

  h1 {
    color: #fff;
    font-weight: bold;
    font-size: 60px;
    line-height: 72px;
  }

  p {
    font-size: 25px;
    line-height: 35px;
    letter-spacing: 21.25;
    color: rgba(255, 255, 255, 0.77);
  }

  .left-text {
    margin-top: 383px;
  }

  .rocketsDiv {
    position: relative;
  }

  .rockets {
    position: absolute;
    max-width: 100%;
    left: 50%;
    transform: rotate(-13deg);
  }

  .earth {
    text-align: center;
    color: #fff;
    font-weight: bold;
    padding-top: 285px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: top;
    margin-top: 200px;
    padding-bottom: 288px;

    h2 {
      font-size: 42px;
      line-height: 64px;
      font-weight: bold;
    }
  }
}

@media (max-width: 992px) {
  .main-content {
    overflow: hidden;
  }

  #landing {
    h1 {
      font-size: 42px;
      line-height: 58px;
    }

    p {
      font-size: 18px;
    }

    a {
      font-size: 14px;
    }

    .left-text {
      margin-top: 244px;
    }
    .earth {
      background-size: contain;
      padding-top: 200px;
      margin-top: 200px;
      margin-bottom: -80px;
    }
  }

  #values-propeller {
    padding-bottom: 148px;
  }

  #apply-now {
    padding-top: 53px;
    padding-bottom: 92px;

    h1 {
      margin-bottom: 44px;
    }

    input {
      margin-bottom: 20px;
    }
  }

  #jobs {
    padding-bottom: 101px;
    padding-top: 105px;

    h1 {
      margin-top: 70px;
    }

    .cover-jobs {
      margin: auto;
      display: block;
    }
  }
}

@media (max-width: 426px) {
  .main-content {
    overflow: hidden;
  }

  #landing {
    h1 {
      font-size: 32px;
      line-height: 43px;
    }

    p {
      font-size: 14px;
      line-height: 25px;
    }

    a {
      font-size: 14px;
      line-height: 22px;
    }

    .rockets {
      width: calc(100vw + 80%);
      position: relative;
      left: 0;
      max-width: calc(100vw + 80%);
    }

    .left-text {
      margin-top: 50px;
    }

    .earth {
      padding-top: 169px;
      padding-bottom: 122px;
      margin-top: 71px;
      background-position: center center;
      background-size: cover;
      margin-bottom: -30px;

      h2 {
        font-size: 26px;
        line-height: 39px;
      }

      a {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  #values-propeller {
    padding-top: 39px;
    padding-bottom: 58px;

    .-bottom-margin {
      margin-bottom: 22px;
    }

    h1 {
      margin-bottom: 22px;
    }

    h2 {
      margin-bottom: 6px;
      font-size: 20px;
      line-height: 34px;
    }
  }

  #jobs {
    padding-top: 60px;
    padding-bottom: 54px;

    h1 {
      margin-top: 49px;
      font-size: 25px;
      line-height: 43px;
      margin-bottom: 18px;
    }

    .accordion {
      margin: 30px -4px 5px -4px;
    }
  }

  #apply-now {
    padding-bottom: 63px;

    h1 {
      margin-bottom: 32px;
      font-size: 28px;
      line-height: 34px;
    }

    input,
    textarea {
      font-size: 12px;
      line-height: 18px;
    }

    .button-apply {
      width: 100%;
      display: block;
      padding-top: 10px;
      padding-bottom: 8px;
      max-width: 100%;
    }
  }
}