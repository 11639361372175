#values-list{
    padding-top: 30px;
    padding-bottom: 24px;
    background-color: #EBEDFB;
    &.-no-background{
        background: transparent;
    }
    h2{
        font-size: 28px;
        line-height: 34px;
        color: #000;
        font-weight: bold;
    }
    p{
        color: rgba(0,0,0,0.5);
        font-size: 16px;
        line-height: 24px;
    }
}

@media (max-width: 425px) {
    .-padding-0-phone{
      padding: 0px;
    }
    
  }

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    #values-list{
        padding: 80px 0;
        background-color: #EBEDFB;
        h2{
            font-size: 28px;
            line-height: 34px;
            color: #000;
            font-weight: bold;
        }
        p{
            color: rgba(0,0,0,0.5);
            font-size: 16px;
            line-height: 24px;
        }
    }
    
}